import React from "react";
import Link from "gatsby-link";
import "../styles/flex.less"
import "../styles/blogcontent.less"

const Navigation = content => {
//  const { share } = content;

  return (
    <div className="flex-left-content">
    <div className="left" >
      <div className="left-h5">Navigation</div>
      <ul className="menu-1" style={{marginBottom: "0"}}>
        <li className="li"><Link className="a" to="/" ><span>Home</span></Link></li>
        <li className="li">{<span>Services</span>}</li>
          <ul className="menu-2" style={{marginBottom: "0"}}>
            <li className="li"><Link className="a" to="/services/artificial-intelligence-solutions"><span>Artificial Intelligence Solutions</span></Link></li>
            <li className="li"><Link className="a" to="/services/consulting"><span>Consulting</span></Link></li>
            <li className="li"><Link className="a" to="/services/contract-software-engineering"><span>Contract Software Engineering</span></Link></li>
          </ul>		
        <li className="li">{<span>Downloads</span>}</li>
          <ul className="menu-2" style={{marginBottom: "0"}}>
            <li className="li"><Link className="a" to="/downloads/dynamic-gin"><span>Dynamic Gin</span></Link></li>
          </ul>		
        <li className="li">{<span>Info</span>}</li>
          <ul className="menu-2" style={{marginBottom: "0"}}>
            <li className="li"><Link className="a" to="/info/contact"><span>Contact</span></Link></li>
            <li className="li"><Link className="a" to="/info/privacy-policy"><span>Privacy Policy</span></Link></li>
          </ul>		
      </ul>		
    </div>
  </div>
  )
}  

export default Navigation;
