import React from "react";
import Link from "gatsby-link";

import "../styles/blog-typography.less";

const TopMenu = (data) => {

  return (
  <div className="top-menu"
    style={{
      display: "inline-grid",
      gridTemplateColumns: "102px 102px 102px 102px", 
      paddingTop: "0.25rem" 
    }}
   >
    <Link to={'/'} style={{fontWeight: "700"}} >Home</Link>
    <div className="dropdown">
      <div className="dropbtn">Services
        {/* <i className="fa fa-caret-down"></i> */}
      </div>
      <div className="dropdown-content">
        <Link to={'/services/artificial-intelligence-solutions'} >Artificial Intelligence Solutions</Link>
        <Link to={'/services/consulting'} >Consulting</Link>
        <Link to={'/services/contract-software-engineering'} >Contract Software Engineering</Link>
      </div>
    </div>
    <div className="dropdown">
      <div className="dropbtn">Downloads
        {/* <i className="fa fa-caret-down"></i> */}
      </div>
      <div className="dropdown-content">
        <Link to={'/downloads/dynamic-gin'} >Dynamic Gin</Link>
      </div>
    </div>
    <div className="dropdown">
      <div className="dropbtn">Info
        {/* <i className="fa fa-caret-down"></i> */}
      </div>
      <div className="dropdown-content">
        <Link to={'/info/contact'} >Contact</Link>
        <Link to={'/info/privacy-policy'} >Privacy Policy</Link>
      </div>
    </div>
  </div> 
  )  
}  

export default TopMenu;
